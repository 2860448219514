@font-face {
font-family: '__familjenGrotesk_3df1c8';
src: url(/_next/static/media/8eb016b972f50a08-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 500;
font-style: normal;
}@font-face {font-family: '__familjenGrotesk_Fallback_3df1c8';src: local("Arial");ascent-override: 102.05%;descent-override: 22.54%;line-gap-override: 0.00%;size-adjust: 97.99%
}.__className_3df1c8 {font-family: '__familjenGrotesk_3df1c8', '__familjenGrotesk_Fallback_3df1c8';font-weight: 500;font-style: normal
}.__variable_3df1c8 {--font-familjen-grotesk: '__familjenGrotesk_3df1c8', '__familjenGrotesk_Fallback_3df1c8'
}

@font-face {
font-family: '__helveticaNeueCondensed_6af1c2';
src: url(/_next/static/media/27eb0bebf059687e-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 600;
font-style: normal;
}@font-face {font-family: '__helveticaNeueCondensed_Fallback_6af1c2';src: local("Arial");ascent-override: 105.97%;descent-override: 24.72%;line-gap-override: 22.08%;size-adjust: 90.68%
}.__className_6af1c2 {font-family: '__helveticaNeueCondensed_6af1c2', '__helveticaNeueCondensed_Fallback_6af1c2';font-weight: 600;font-style: normal
}.__variable_6af1c2 {--font-helvetica-neue-condensed: '__helveticaNeueCondensed_6af1c2', '__helveticaNeueCondensed_Fallback_6af1c2'
}

@font-face {
font-family: '__helveticaNeue_3c9c0c';
src: url(/_next/static/media/9ba4191f43207ecf-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 600;
font-style: normal;
}

@font-face {
font-family: '__helveticaNeue_3c9c0c';
src: url(/_next/static/media/2eba51fae4590a5f-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 500;
font-style: italic;
}

@font-face {
font-family: '__helveticaNeue_3c9c0c';
src: url(/_next/static/media/f9de71e6508aee33-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
font-style: italic;
}

@font-face {
font-family: '__helveticaNeue_3c9c0c';
src: url(/_next/static/media/1b097d62f924b758-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 500;
font-style: normal;
}

@font-face {
font-family: '__helveticaNeue_3c9c0c';
src: url(/_next/static/media/aa5a552f5202f68a-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
font-style: normal;
}@font-face {font-family: '__helveticaNeue_Fallback_3c9c0c';src: local("Arial");ascent-override: 93.98%;descent-override: 21.11%;line-gap-override: 19.76%;size-adjust: 101.31%
}.__className_3c9c0c {font-family: '__helveticaNeue_3c9c0c', '__helveticaNeue_Fallback_3c9c0c'
}.__variable_3c9c0c {--font-helvetica-neue: '__helveticaNeue_3c9c0c', '__helveticaNeue_Fallback_3c9c0c'
}

